import React, { useEffect, useState } from "react";
import * as Icon from "react-feather";
import { Row, Col } from "reactstrap";
import logo from "../assets/logo.jpeg";
import "./style.css";
const Header = () => {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    function handleScroll() {
      if (window.scrollY > 20) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    }

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div
      className={`${ scrolled && "header-show" } w-100 header  p-4  d-flex justify-content-center align-items-center`}
    >
      <Row>
        <Col xs={12} sm={4} md={3}>
          <img src={logo} alt="Logo" height="auto" width="150" style={{padding: "10px",}}/>
        </Col>
      </Row>
    </div>
  );
};

export default Header;
