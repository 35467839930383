import React from "react";
import { Col, Row } from "reactstrap";
import "./style.css";
const Footer = () => {
  return (
    <div className="w-100 footer d-flex justify-content-center align-items-center p-5">
       <a href="https://berryhotels.com.tr/">Berry Hotel</a>
    </div>
  );
};
export default Footer;
