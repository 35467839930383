import * as Icon from "react-feather";
export const languageDropdownData = [
  {
    text: "Turkish",
    type: "tr",
    id: 1,
  },
  {
    text: "English",
    type: "en",
    id: 2,
  },
  {
    text: "Russian",
    type: "ru",
    id: 3,
  },
  {
    text: "German",
    type: "alm",
    id: 4,
  },
];
export const languageTableData = [
  {
    text: "Berry_FactSheet_TR",
    id: 1,
    type: "tr",
    url: "Berry_Facseet_tr.pdf",
    isChecked: false,
    icon: <Icon.Download size={15} />,
  },
  {
    text: "Berry_FactSheet_EN",
    id: 5,
    type: "en",
    url: "Berry_Facseet_ing.pdf",
    isChecked: false,
    icon: <Icon.Download size={15} />,
  },
];

export const imageDropdownData = [
  {
    text: "Tüm resimler",
    type: "all",
    id: 1,
  },
  {
    text: "Odalar",
    type: "rooms",
    id: 2,
  },
  {
    text: "Loby",
    type: "loby",
    id: 3,
  },
  {
    text: "Restaurant & Bar",
    type: "restoran",
    id: 4,
  },
  {
    text: "Genel Alan",
    type: "genel",
    id: 5,
  },
  {
    text: "Spa",
    type: "spa",
    id: 6,
  },
  {
    text: "Çocuk Klubü",
    type: "cocuk-klubu",
    id: 7,
  },
  {
    text: "Havuz & Plaj",
    type: "havuz-plaj",
    id: 8,
  },
  {
    text: "Eğlence & Animasyon",
    type: "eglence-animasyon",
    id: 9,
  },
  {
    text: "Spor & Fitness",
    type: "spor-fitness",
    id: 10,
  },
];
const imageTableData = [
/*   {
    id: 1,
    name: "Tüm Resimler",
    type: "all",
    description: "Mükemmel bir konuma sahip, lüks bir otel.",
    image: "all.jpg",
  }, */

  {
    id: 1,
    name: "Odalar",
    type: "rooms",
    description: "Şehir merkezinde, modern tasarımlı bir otel.",
    image: "oda1.jpeg",
  },
  {
    id: 2,
    name: "Odalar",
    type: "rooms",
    description: "Şehir merkezinde, modern tasarımlı bir otel.",
    image: "oda2.jpeg",
  },
  {
    id: 3,
    name: "Odalar",
    type: "rooms",
    description: "Şehir merkezinde, modern tasarımlı bir otel.",
    image: "oda3.jpeg",
  },
  {
    id: 4,
    name: "Odalar",
    type: "rooms",
    description: "Şehir merkezinde, modern tasarımlı bir otel.",
    image: "oda4.jpeg",
  },
  {
    id: 5,
    name: "Odalar",
    type: "rooms",
    description: "Şehir merkezinde, modern tasarımlı bir otel.",
    image: "oda5.jpeg",
  },
  {
    id: 6,
    name: "Odalar",
    type: "rooms",
    description: "Şehir merkezinde, modern tasarımlı bir otel.",
    image: "oda6.jpeg",
  },
  {
    id: 7,
    name: "Odalar",
    type: "rooms",
    description: "Şehir merkezinde, modern tasarımlı bir otel.",
    image: "oda7.jpeg",
  },
  {
    id: 8,
    name: "Odalar",
    type: "rooms",
    description: "Şehir merkezinde, modern tasarımlı bir otel.",
    image: "oda8.jpeg",
  },
  {
    id: 9,
    name: "Odalar",
    type: "rooms",
    description: "Şehir merkezinde, modern tasarımlı bir otel.",
    image: "oda9.jpeg",
  },
  {
    id: 10,
    name: "Odalar",
    type: "rooms",
    description: "Şehir merkezinde, modern tasarımlı bir otel.",
    image: "oda10.jpeg",
  },
  {
    id: 11,
    name: "Odalar",
    type: "rooms",
    description: "Şehir merkezinde, modern tasarımlı bir otel.",
    image: "oda11.jpeg",
  },
  {
    id: 12,
    name: "Loby",
    type: "loby",
    description: "Şehir merkezinde, modern tasarımlı bir otel.",
    image: "lobby1.jpeg",
  },
  {
    id: 13,
    name: "Loby",
    type: "loby",
    description: "Şehir merkezinde, modern tasarımlı bir otel.",
    image: "lobby2.jpeg",
  },
  {
    id: 14,
    name: "Loby",
    type: "loby",
    description: "Şehir merkezinde, modern tasarımlı bir otel.",
    image: "lobby3.jpeg",
  },
  {
    id: 15,
    name: "Loby",
    type: "loby",
    description: "Şehir merkezinde, modern tasarımlı bir otel.",
    image: "lobby4.jpeg",
  },
  {
    id: 16,
    name: "Loby",
    type: "loby",
    description: "Şehir merkezinde, modern tasarımlı bir otel.",
    image: "lobby5.jpeg",
  },
  {
    id: 17,
    name: "Loby",
    type: "loby",
    description: "Şehir merkezinde, modern tasarımlı bir otel.",
    image: "lobby6.jpeg",
  },
  
  {
    id: 18,
    name: "Restaurant & Bar",
    type: "restoran",
    description: "Şehir merkezinde, modern tasarımlı bir otel.",
    image: "restoran1.jpeg",
  },
  {
    id: 19,
    name: "Restaurant & Bar",
    type: "restoran",
    description: "Şehir merkezinde, modern tasarımlı bir otel.",
    image: "restoran2.jpeg",
  },
  {
    id: 20,
    name: "Restaurant & Bar",
    type: "restoran",
    description: "Şehir merkezinde, modern tasarımlı bir otel.",
    image: "restoran3.jpeg",
  },
  {
    id: 21,
    name: "Restaurant & Bar",
    type: "restoran",
    description: "Şehir merkezinde, modern tasarımlı bir otel.",
    image: "restoran4.jpeg",
  },
  {
    id: 22,
    name: "Restaurant & Bar",
    type: "restoran",
    description: "Şehir merkezinde, modern tasarımlı bir otel.",
    image: "restoran5.jpeg",
  },
  
  {
    id: 22,
    name: "Genel Alan",
    type: "genel",
    description: "Şehir merkezinde, modern tasarımlı bir otel.",
    image: "genelalan1.jpeg",
  },
  {
    id: 23,
    name: "Genel Alan",
    type: "genel",
    description: "Şehir merkezinde, modern tasarımlı bir otel.",
    image: "genelalan2.jpeg",
  },
  {
    id: 24,
    name: "Genel Alan",
    type: "genel",
    description: "Şehir merkezinde, modern tasarımlı bir otel.",
    image: "genelalan3.jpeg",
  },
  {
    id: 25,
    name: "Genel Alan",
    type: "genel",
    description: "Şehir merkezinde, modern tasarımlı bir otel.",
    image: "genelalan4.jpeg",
  },
  {
    id: 26,
    name: "Genel Alan",
    type: "genel",
    description: "Şehir merkezinde, modern tasarımlı bir otel.",
    image: "genelalan5.jpeg",
  },
  {
    id: 27,
    name: "Genel Alan",
    type: "genel",
    description: "Şehir merkezinde, modern tasarımlı bir otel.",
    image: "genelalan6.jpeg",
  },
  {
    id: 28,
    name: "Genel Alan",
    type: "genel",
    description: "Şehir merkezinde, modern tasarımlı bir otel.",
    image: "genelalan7.jpeg",
  },
  {
    id: 29,
    name: "Genel Alan",
    type: "genel",
    description: "Şehir merkezinde, modern tasarımlı bir otel.",
    image: "genelalan8.jpeg",
  },

  
  {
    id: 30,
    name: "Eğlence & Animasyon",
    type: "eglence-animasyon",
    description: "Şehir merkezinde, modern tasarımlı bir otel.",
    image: "eglenceanimasyon1.jpg",
  },
    
  {
    id: 31,
    name: "Eğlence & Animasyon",
    type: "eglence-animasyon",
    description: "Şehir merkezinde, modern tasarımlı bir otel.",
    image: "eglenceanimasyon2.jpg",
  },

  {
    id: 32,
    name: "Eğlence & Animasyon",
    type: "eglence-animasyon",
    description: "Şehir merkezinde, modern tasarımlı bir otel.",
    image: "eglenceanimasyon3.jpg",
  },
  {
    id: 33,
    name: "Eğlence & Animasyon",
    type: "eglence-animasyon",
    description: "Şehir merkezinde, modern tasarımlı bir otel.",
    image: "eglenceanimasyon4.jpg",
  },
  {
    id: 34,
    name: "Eğlence & Animasyon",
    type: "eglence-animasyon",
    description: "Şehir merkezinde, modern tasarımlı bir otel.",
    image: "eglenceanimasyon5.jpg",
  },
  {
    id: 35,
    name: "SPA",
    type: "spa",
    description: "Şehir merkezinde, modern tasarımlı bir otel.",
    image: "spa1.jpg",
  },
  {
    id: 36,
    name: "SPA",
    type: "spa",
    description: "Şehir merkezinde, modern tasarımlı bir otel.",
    image: "spa2.jpg",
  },

  {
    id: 37,
    name: "Çocuk Klubü",
    type: "cocuk-klubu",
    description: "Şehir merkezinde, modern tasarımlı bir otel.",
    image: "cocukklubü1.jpeg",
  },
  
  {
    id: 38,
    name: "Çocuk Klubü",
    type: "cocuk-klubu",
    description: "Şehir merkezinde, modern tasarımlı bir otel.",
    image: "cocukklubü2.jpeg",
  },
  
  {
    id: 39,
    name: "Çocuk Klubü",
    type: "cocuk-klubu",
    description: "Şehir merkezinde, modern tasarımlı bir otel.",
    image: "cocukklubü3.jpeg",
  },
  
  {
    id: 40,
    name: "Havuz & Plaj",
    type: "havuz-plaj",
    description: "Şehir merkezinde, modern tasarımlı bir otel.",
    image: "havuz1.jpeg",
  },
  {
    id: 41,
    name: "Havuz & Plaj",
    type: "havuz-plaj",
    description: "Şehir merkezinde, modern tasarımlı bir otel.",
    image: "havuz2.jpeg",
  },
  {
    id: 43,
    name: "Havuz & Plaj",
    type: "havuz-plaj",
    description: "Şehir merkezinde, modern tasarımlı bir otel.",
    image: "havuz3.jpeg",
  },
  {
    id: 44,
    name: "Havuz & Plaj",
    type: "havuz-plaj",
    description: "Şehir merkezinde, modern tasarımlı bir otel.",
    image: "havuz4.jpeg",
  },

  {
    id: 45,
    name: "Havuz & Plaj",
    type: "havuz-plaj",
    description: "Şehir merkezinde, modern tasarımlı bir otel.",
    image: "havuz5.jpeg",
  },
  {
    id: 46,
    name: "Havuz & Plaj",
    type: "havuz-plaj",
    description: "Şehir merkezinde, modern tasarımlı bir otel.",
    image: "havuz6.jpeg",
  },
  {
    id: 47,
    name: "Havuz & Plaj",
    type: "havuz-plaj",
    description: "Şehir merkezinde, modern tasarımlı bir otel.",
    image: "havuz7.jpeg",
  },
  {
    id: 48,
    name: "Havuz & Plaj",
    type: "havuz-plaj",
    description: "Şehir merkezinde, modern tasarımlı bir otel.",
    image: "havuz8.jpeg",
  },
  {
    id: 49,
    name: "Havuz & Plaj",
    type: "havuz-plaj",
    description: "Şehir merkezinde, modern tasarımlı bir otel.",
    image: "havuz9.jpeg",
  },
  {
    id: 50,
    name: "Havuz & Plaj",
    type: "havuz-plaj",
    description: "Şehir merkezinde, modern tasarımlı bir otel.",
    image: "havuz10.jpeg",
  },
  {
    id: 51,
    name: "Havuz & Plaj",
    type: "havuz-plaj",
    description: "Şehir merkezinde, modern tasarımlı bir otel.",
    image: "havuz11.jpeg",
  },

  {
    id: 52,
    name: "Havuz & Plaj",
    type: "havuz-plaj",
    description: "Şehir merkezinde, modern tasarımlı bir otel.",
    image: "havuz12.jpeg",
  },
  {
    id: 53,
    name: "Havuz & Plaj",
    type: "havuz-plaj",
    description: "Şehir merkezinde, modern tasarımlı bir otel.",
    image: "havuz13.jpeg",
  },


];
const sertificationsData = [
  {
    text: " ",
    id: 1,
    url: " ",
    isChecked: false,
    icon: <Icon.Download size={15} />,
  },
];

const videosData = [
  {
    text: "Berry.mp4",
    id: 1,
    url: "videos/berry.mp4",
    isChecked: false,
    icon: <Icon.Download size={15} />,
  },
];

const logosData = [
  {
    text: "Berry_Logo",
    id: 1,
    url: "logos/berry_logo.png",
    isChecked: false,
    icon: <Icon.Download size={15} />,
  },
  {
    text: "Berry_Logo_Pdf",
    id: 2,
    url: "logos/berry_logo.pdf",
    isChecked: false,
    icon: <Icon.Download size={15} />,
  },
  {
    text: "Berry_Logo_Jpg",
    id: 3,
    url: "logos/berry_logo.jpg",
    isChecked: false,
    icon: <Icon.Download size={15} />,
  },
];
export const allData = {
  languageDropdownData,
  languageTableData,
  imageDropdownData,
  imageTableData,
  sertificationsData,
  videosData,
  logosData,
};
